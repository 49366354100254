import { Chip, Grid, Typography } from '@mui/material'; //

import BreadCrumb from './Breadcrumb';

import color from 'assets/scss/_themes-vars.module.scss';
import './style.scss';

const SubHeaderComponent = ({
    title,
    module,
    customPathEndPointName,
    homeIcon = false,
    subModule,
    totalChipValue,
    dynamicLink
}: {
    title: string;
    module: string;
    customPathEndPointName?: string;
    sub?: boolean;
    homeIcon?: boolean;
    subModule?: string[];
    totalChipValue?: number;
    dynamicLink?: string;
}) => {
    return (
        <Grid className="sub-header" item xs={12} padding="20px 20px 10px 20px" borderRadius={'7px 7px 0px 0px'}>
            <Grid
                borderRadius={3}
                container
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                className="sub-header-container"
            >
                <Grid item>
                    <Typography
                        className="breadcrumb-title"
                        padding={2}
                        paddingLeft={3}
                        variant="h1"
                        sx={{ fontSize: '0.938rem', fontWeight: 900, color: color.grey1000 }}
                    >
                        {title}
                        {totalChipValue ? (
                            <Chip label={totalChipValue} size="small" sx={{ ml: 1, backgroundColor: color.primaryLight }} />
                        ) : (
                            <></>
                        )}
                    </Typography>
                </Grid>
                <Grid item display={'flex'} alignItems={'center'}>
                    <BreadCrumb
                        homeIcon={homeIcon}
                        customPathEndPointName={customPathEndPointName}
                        module={module}
                        subModule={subModule}
                        dynamicLink={dynamicLink}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SubHeaderComponent;
