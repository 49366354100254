// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Chip, Divider, Grid, List, ListItem, ListItemAvatar, Tooltip, Typography } from '@mui/material';
import color from 'assets/scss/_themes-vars.module.scss';
// assets
import { getFormattedUserName, GetImageUrl } from 'utils/common';
// import { NotificationSystemIcon } from 'ui-component/Icons';
import dayjs from 'dayjs';
import routeConstant from 'routes/routeConstant';
import { useDispatch } from 'react-redux';
import { SidePanelSagaAction } from 'store/sidepanel/saga';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: '20px 0px 20px 10px',
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

type props = {
    notificationsList: { [key: string]: any }[];
    handleClose: () => void;
};
const NotificationList = (props: props) => {
    const { notificationsList, handleClose } = props;
    const theme = useTheme();
    const dispatch = useDispatch();

    const chipSX = {
        height: 27,
        padding: '5px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: color.primaryDark,
        bgcolor: color.primaryLight,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: color.primaryLight,
        bgcolor: color.primaryDark
    };

    const handleNavigate = (route: string, id: string) => {
        handleClose();
        window.open(`${window.location.origin}${route}`, '_blank');
        dispatch(SidePanelSagaAction.updateNotificationStatus(id));
    };

    const notifyList = notificationsList?.map((item: { [key: string]: string }) => ({
        ...item,
        name: item?.update_name ?? item?.unit_name,
        image: item?.main_img ?? item?.unit_image,
        link: item?.update_id ? `${routeConstant.updatesProfile}/${item?.update_id}` : routeConstant.properties
    }));

    return (
        <List
            sx={{
                width: '100%',
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7.5
                }
            }}
        >
            {notifyList?.map((notification: { [key: string]: any }) => (
                <ListItemWrapper onClick={() => handleNavigate(notification?.link, notification?._id)}>
                    <ListItem alignItems="center">
                        <ListItemAvatar>
                            {notification?.image ? (
                                <img
                                    src={GetImageUrl(notification.image)}
                                    alt="Client_Image"
                                    style={{
                                        borderRadius: '50%',
                                        width: '2.9688rem',
                                        height: '2.9688rem',
                                        marginRight: '0.625rem',
                                        objectFit: 'cover'
                                    }}
                                />
                            ) : (
                                <Avatar
                                    sx={{ backgroundColor: color.primaryMedium, color: '#000000' }}
                                    aria-label="recipe"
                                    style={{
                                        width: '2.9688rem',
                                        height: '2.9688rem',
                                        marginRight: '0.625rem',
                                        fontSize: '14px',
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    {getFormattedUserName(notification?.name?.slice(0, 1) ?? '')}
                                </Avatar>
                            )}
                        </ListItemAvatar>
                        <Grid item xs={12}>
                            <Tooltip title={notification?.name}>
                                <TruncatedTypography variant="h4">{notification?.name}</TruncatedTypography>
                            </Tooltip>
                        </Grid>
                    </ListItem>
                    <Grid container direction="column" className="list-container" width={'80%'}>
                        <Grid item xs={12} sx={{ pb: 2, fontSize: '15px', maxWidth: '80%' }}>
                            {/* <Typography variant="subtitle2" style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '80%' }}>
                                Mariana Vozone
                            </Typography> */}
                            <Typography variant="subtitle2" style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '80%' }}>
                                {dayjs(notification?.date).format('DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item>
                                    <Chip
                                        label={notification?.status}
                                        sx={notification?.status?.includes('New') ? chipWarningSX : chipErrorSX}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItemWrapper>
            ))}
            <Divider />
            {/* <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{ backgroundColor: color.primaryMedium, color: '#000000' }}
                            aria-label="recipe"
                            style={{
                                width: '2.9688rem',
                                height: '2.9688rem',
                                marginRight: '0.625rem',
                                fontSize: '14px'
                            }}
                        >
                            {getFormattedUserName('A A')}
                        </Avatar>
                    </ListItemAvatar>
                    <Grid item xs={12}>
                        <Tooltip title={'UPDATE | NOVEMBER 2024'}>
                            <TruncatedTypography variant="h4">UPDATE | NOVEMBER 2024</TruncatedTypography>
                        </Tooltip>
                    </Grid>
                </ListItem>
                <Grid container direction="column" className="list-container" width={'80%'}>
                    <Grid item xs={12} sx={{ pb: 2, fontSize: '15px' }}>
                        <Typography variant="caption" display="block" gutterBottom>
                            22/02/24
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Chip label="Pending" sx={chipErrorSX} />
                            </Grid>
                            <Grid item>
                                <Chip label="New" sx={chipWarningSX} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{ backgroundColor: color.primaryLight, color: '#000000' }}
                            aria-label="recipe"
                            style={{
                                width: '2.9688rem',
                                height: '2.9688rem'
                            }}
                        >
                            <NotificationSystemIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <Grid item xs={12}>
                        <Tooltip title={'System Maintainance'}>
                            <TruncatedTypography variant="h4">System Maintainance</TruncatedTypography>
                        </Tooltip>
                    </Grid>
                </ListItem>
                <Grid container direction="column" className="list-container" width={'80%'}>
                    <Grid item xs={12} sx={{ pb: 2, fontSize: '15px' }}>
                        <Typography variant="subtitle2">We have successfully received your request</Typography>
                        <Typography variant="caption" display="block" gutterBottom>
                            22/02/24
                        </Typography>
                    </Grid>
                </Grid>
            </ListItemWrapper> */}
        </List>
    );
};

export default NotificationList;

const TruncatedTypography = styled(Typography)(() => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '230px'
}));
