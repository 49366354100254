import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Card, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Menu, OutlinedInput, Popper } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons-react';
import { shouldForwardProp } from '@mui/system';

import color from 'assets/scss/_themes-vars.module.scss';
import useAuth from 'hooks/useAuth';
import { sideBarItem } from 'layout/MainLayout/MenuList';
import { NavItemType } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { selectedModulesSelector, sidepanelStateSelector } from 'store/sidepanel/selector';
import { SidepanelReducerAction } from 'store/sidepanel/slice';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
    }
}));

interface Props {
    value: string;
    setValue: (value: React.ChangeEvent<HTMLInputElement>) => void;
    popupState: any;
}

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }: Props) => {
    const theme = useTheme();

    return (
        <OutlineInputStyle
            id="input-search-header"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e)}
            placeholder="Search"
            startAdornment={
                <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="16px" color={theme.palette.grey[500]} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    <HeaderAvatarStyle variant="rounded">
                        <IconAdjustmentsHorizontal stroke={1.5} size="20px" />
                    </HeaderAvatarStyle>
                    <Box sx={{ ml: 2 }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
                                color: theme.palette.orange.dark,
                                '&:hover': {
                                    background: theme.palette.orange.dark,
                                    color: theme.palette.orange.light
                                }
                            }}
                            {...bindToggle(popupState)}
                        >
                            <IconX stroke={1.5} size="20px" />
                        </Avatar>
                    </Box>
                </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight' }}
        />
    );
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const theme = useTheme();
    const { loggedInUserType } = useAuth();
    const dispatch = useDispatch();
    const sidebarItems = sideBarItem(String(loggedInUserType));

    const [anchorEl, setAnchorEl] = useState<Element | (() => Element) | null | undefined>(null);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { search } = useSelector(sidepanelStateSelector);
    const selectedModules = useSelector(selectedModulesSelector);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(SidepanelReducerAction.setGlobalSearch(String(e.target.value)));
    };

    const handleMenuItemChange = (e: any, label: any) => {
        const selectedModes = e?.target?.checked
            ? [...selectedModules, label]
            : selectedModules?.filter((item: string) => !item.includes(label));
        dispatch(SidepanelReducerAction.setSelectedModules([...selectedModes]));
        handleClose();
    };

    return (
        <>
            <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2 }}>
                                <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                    <IconSearch stroke={1.5} size="19.2px" />
                                </HeaderAvatarStyle>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch value={search} setValue={handleChange} popupState={popupState} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <OutlineInputStyle
                    id="input-search-header"
                    value={search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                    placeholder="Search"
                    startAdornment={
                        <InputAdornment position="start">
                            <IconSearch stroke={1.5} size="16px" color={theme.palette.grey[500]} />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <HeaderAvatarStyle variant="rounded">
                                <IconAdjustmentsHorizontal
                                    onClick={(event) => {
                                        setAnchorEl(event?.currentTarget);
                                    }}
                                    stroke={1.5}
                                    size="20px"
                                />
                                {anchorEl && (
                                    <Menu
                                        sx={{
                                            '& .MuiPaper-root': {
                                                boxShadow:
                                                    '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 3px 0px rgba(0,0,0,0.12)'
                                            }
                                        }}
                                        id="menu-user-details-card"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        variant="selectedMenu"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                    >
                                        <FormGroup style={{ padding: '0rem 1rem' }}>
                                            {sidebarItems &&
                                                sidebarItems[0]?.children
                                                    ?.slice(1)
                                                    .map(
                                                        (item: NavItemType) =>
                                                            item.id !== 'financials' && (
                                                                <FormControlLabel
                                                                    key={item.id}
                                                                    control={<Checkbox />}
                                                                    label={item.id && item.id.charAt(0).toUpperCase() + item.id.slice(1)}
                                                                    checked={Boolean(
                                                                        selectedModules?.find((data: string) => item.id?.includes(data))
                                                                    )}
                                                                    onChange={(e) => handleMenuItemChange(e, item.id)}
                                                                />
                                                            )
                                                    )}
                                        </FormGroup>
                                    </Menu>
                                )}
                            </HeaderAvatarStyle>
                        </InputAdornment>
                    }
                    aria-describedby="search-helper-text"
                    sx={{ background: color.grey50 }}
                    inputProps={{ 'aria-label': 'weight' }}
                />
            </Box>
        </>
    );
};

export default SearchSection;
