import { call, put } from 'redux-saga/effects';
import { createSliceSaga } from 'store/saga-helper';
import { ClientsReducerAction } from './slice';
import { AxiosResponse } from 'axios';
import {
    CreateClient,
    DeleteClientById,
    DownloadClientsList,
    GetAllCountries,
    GetClientDetailsById,
    GetClientsData,
    GetUnitFinancialsByClientId,
    GetUnitsByClientId,
    UpdateClient,
    UpdateClientPassword
} from 'services/clients';
import { ConvertToQueryString, DownloadFile } from 'utils/common';
import { SnackbarReducerAction } from 'store/snackbar/slice';
import { PropertiesSagaActions } from 'store/properties/saga';
import { PropertiesReducerAction } from 'store/properties/slice';
import routeConstant from 'routes/routeConstant';

const saga = createSliceSaga({
    name: 'clients-state',
    caseSagas: {
        *fetchClientsList(action: { payload: { [key: string]: any } }) {
            try {
                const { exportType } = action.payload;
                if (Boolean(exportType)) {
                    yield put(ClientsReducerAction.setIsLoading(true));
                    try {
                        const response: { [key: string]: any } = yield call(DownloadClientsList, ConvertToQueryString(action.payload));
                        DownloadFile({ response, fileName: 'ClientsList', fileType: 'xlsx' });
                    } catch (error) {
                        console.log('Error in download data API', error);
                        throw new Error();
                    }
                } else {
                    yield put(ClientsReducerAction.setIsLoading(true));
                    const response: AxiosResponse = yield call(GetClientsData, ConvertToQueryString(action.payload));
                    if (response?.status === 200) {
                        ClientsReducerAction.setClientsDetails([]);
                        ClientsReducerAction.setTotal(0);
                        yield put(ClientsReducerAction.setClientsDetails(response?.data));
                        yield put(ClientsReducerAction.setTotal(response?.data?.totalCount));
                    }
                }
            } catch (error) {
                console.log('Error in Clients API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsLoading(false));
            }
        },
        *createClient(action: { payload: { [key: string]: any } }) {
            try {
                const { values, handleNavigate } = action.payload;
                yield put(ClientsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(CreateClient, values);
                if (response?.status === 200) {
                    ClientsReducerAction.setSelectedClient({});
                    yield put(ClientsSagaActions.fetchClientsList({ page: 1, pageSize: 12 }));
                    yield put(ClientsReducerAction.setClientFormTab(1));
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Client Created Successfully'
                        })
                    );
                    handleNavigate(`${routeConstant.clientsEdit}/${response?.data?.data?._id}`);
                } else {
                    yield put(
                        response?.data?.message &&
                            SnackbarReducerAction.openSnackbar({
                                open: true,
                                message: response?.data?.message
                            })
                    );
                }
            } catch (error) {
                console.log('Error in save Client API', error);
                throw new Error();
            }
        },
        *updateClient(action: { payload: { [key: string]: any } }) {
            try {
                yield put(ClientsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(UpdateClient, action.payload);
                if (response?.status === 200) {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Client details Updated Successfully'
                        })
                    );
                } else {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Update Client API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsLoading(false));
            }
        },
        *getClientById(action: { payload: string }) {
            try {
                yield put(ClientsReducerAction.setIsClientDetailsLoading(true));
                const response: AxiosResponse = yield call(GetClientDetailsById, String(action.payload));
                if (response?.status === 200) {
                    ClientsReducerAction.setSelectedClient({});
                    yield put(ClientsReducerAction.setSelectedClient(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Get client by Id API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsClientDetailsLoading(false))
            }
        },
        *deleteClientDataById(action: { payload: { id: string; property_id: string } }) {
            try {
                const { id, property_id } = action.payload;
                yield put(ClientsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(DeleteClientById, id);
                if (response?.status === 200) {
                    ClientsReducerAction.setSelectedClient({});
                    if (property_id) {
                        PropertiesReducerAction.setSelectedProperty([]);
                        yield put(PropertiesSagaActions.fetchPropertyClientsList({ userId: property_id, page: 1, pageSize: 12 }));
                        yield put(PropertiesSagaActions.fetchPropertiesDetailsById(property_id));
                    } else {
                        yield put(ClientsSagaActions.fetchClientsList({ page: 1, pageSize: 12 }));
                    }
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Client deleted Successfully'
                        })
                    );
                } else {
                    yield put(
                        response?.data?.message &&
                            SnackbarReducerAction.openSnackbar({
                                open: true,
                                message: response?.data?.message
                            })
                    );
                }
            } catch (error) {
                console.log('Error in Delete Client API', error);
                throw new Error();
            } 
        },
        *getCountriesList() {
            try {
                const response: AxiosResponse = yield call(GetAllCountries);
                if (response?.status === 200) {
                    ClientsReducerAction.setCountriesList([]);
                    yield put(ClientsReducerAction.setCountriesList(response?.data?.data));
                }
            } catch (error) {
                console.log('Error in Countries list API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsLoading(false));
            }
        },
        *fetchUnitsByClientId(action: { payload: { [key: string]: any } }) {
            try {
                const { id } = action.payload;
                yield put(PropertiesReducerAction.setPropertyUnitsListLoading(true));
                const response: AxiosResponse = yield call(GetUnitsByClientId, {
                    id: id,
                    query: ConvertToQueryString(action.payload)
                });
                if (response?.status === 200) {
                    PropertiesReducerAction.setPropertyUnitsDetails([]);
                    PropertiesReducerAction.setUnitsTotal(0);
                    yield put(PropertiesReducerAction.setUnitsPage(response?.data?.currentPage));
                    yield put(PropertiesReducerAction.setPropertyUnitsDetails(response));
                    yield put(PropertiesReducerAction.setUnitsTotal(response?.data?.total));
                }
            } catch (error) {
                console.log('Error in Units List By Client Id API', error);
                throw new Error();
            } finally {
                yield put(PropertiesReducerAction.setPropertyUnitsListLoading(false));
            }
        },
        *fetchUnitFinancialsByClientId(action: { payload: { [key: string]: any } }) {
            try {
                const { id, page, pageSize } = action.payload;
                yield put(ClientsReducerAction.setIsFinancialsListLoading(true));
                const response: AxiosResponse = yield call(GetUnitFinancialsByClientId, {
                    id: id,
                    query: ConvertToQueryString({ page, pageSize })
                });
                if (response?.status === 200) {
                    ClientsReducerAction.setClientFinancials([]);
                    ClientsReducerAction.setFinancialsTotal(0);
                    yield put(ClientsReducerAction.setFinancialsPage(response?.data?.currentPage));
                    yield put(ClientsReducerAction.setClientFinancials(response?.data?.data));
                    yield put(ClientsReducerAction.setFinancialsTotal(response?.data?.total));
                }
            } catch (error) {
                console.log('Error in Units List By Client Id API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsFinancialsListLoading(false));
            }
        },
        *resetClientPassword(action: { payload: { [key: string]: any } }) {
            try {
                const { navigate } = action.payload;
                yield put(ClientsReducerAction.setIsLoading(true));
                const response: AxiosResponse = yield call(UpdateClientPassword, action.payload);
                if (response?.status === 200) {
                    navigate(routeConstant.clients);
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: 'Password Reset Successfully'
                        })
                    );
                } else {
                    yield put(
                        SnackbarReducerAction.openSnackbar({
                            open: true,
                            message: response?.data?.message ?? 'Password Not Updated Successfully'
                        })
                    );
                }
            } catch (error) {
                console.log('Error in Update Client Password API', error);
                throw new Error();
            } finally {
                yield put(ClientsReducerAction.setIsLoading(false));
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: ClientsSagaActions, saga: ClientsSaga, name: ClientsSagaName } = saga;
