import axiosServices from 'utils/axios';

export const GetGlobalSearchData = async (query?: string) => {
    try {
        const response = await axiosServices.get(`/search?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};
export const GetNotificationsData = async (query?: string) => {
    try {
        const response = await axiosServices.get(`/notification/list?${query}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const UpdateNotificationStatus = async (id?: string) => {
    try {
        const response = await axiosServices.put(id ? `/notification/markAsRead/${id}` : '/notification/markAsRead');
        return response;
    } catch (error) {
        return error;
    }
};
