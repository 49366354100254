import { Grid } from '@mui/material';

import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UnitProfile from 'views/properties/Components/ProfileFormsTabs/PropertyUnits/ModalForms/UnitFormTabs/UnitProfile';
import FinancialsTab from 'views/properties/Components/ProfileFormsTabs/PropertyUnits/ModalForms/UnitFormTabs/FinancialTab';
import { TabPanel } from 'views/properties/utils';
import './style.scss';
import DocumentTabs from './UnitFormTabs/Documents';
import ModificationTab from '../UnitModificationForm/ModificationTab';
import { PropertiesSagaActions } from 'store/properties/saga';

import { selectedPropertySelector } from 'store/properties/selector';

const UnitFormTabs = ({ value }: { value: number }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const selectedProperty = useSelector(selectedPropertySelector);
    const property_id = selectedProperty?._id ?? searchParams.get('property_id');
    useEffect(() => {
        id && dispatch(PropertiesSagaActions.fetchPropertyUnitById(id));
        property_id && dispatch(PropertiesSagaActions.fetchPropertyDetails(property_id));
    }, [id]);

    return (
        <>
            <Grid padding="20px 20px 10px 20px" className=" property-forms-data-container" item width={'100%'}>
                <TabPanel value={value} index={0}>
                    <UnitProfile />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <FinancialsTab />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ModificationTab />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <DocumentTabs />
                </TabPanel>
            </Grid>
        </>
    );
};

export default UnitFormTabs;
