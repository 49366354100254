import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type ReactQuillPropsType = {
    setValue?: any;
    field?: string;
    initialValue?: string;
    error?: string;
};

const ReactQuillDemo = ({ field, setValue, initialValue, error }: ReactQuillPropsType) => {
    const [text, setText] = useState(initialValue);
    useEffect(() => {
        setText(initialValue);
        return () => {
            setText('');
        };
    }, [initialValue]);

    const handleChange = (value: string) => {
        setValue(field, value);
        setText(value);
    };
    return (
        <>
            <ReactQuill id="description" value={text} onChange={handleChange} />
            <FormHelperText sx={{ margin: '0px !important' }} error>
                {error}
            </FormHelperText>
        </>
    );
};

export default ReactQuillDemo;
