import { createSlice } from '@reduxjs/toolkit';
import { UpdatesDetailsType, updatesListDataType } from './types';

export const initialState: UpdatesDetailsType = {
    updatesListByUserId: [],
    isLoading: false,
    updateDetailGalleryImages: [],
    isDeleteUpdateLoading: false,
    isUpdateDetailsLoading: false,
    isDeleteModalOpen: false,
    selectedUpdate: {} as updatesListDataType,
    page: 1,
    pageSize: 12,
    total: 0,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    search: '',
    updateFormsTab: 0
};

const updates = createSlice({
    name: 'updatesState',
    initialState,
    reducers: {
        setUpdatesListByUserId(state, action) {
            state.updatesListByUserId = action.payload.data;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setIsDeleteUpdateLoading(state, action) {
            state.isDeleteUpdateLoading = action.payload;
        },
        setToggleDeleteModal(state, action) {
            state.isDeleteModalOpen = action.payload;
        },
        setSelectedUpdate(state, action) {
            state.selectedUpdate = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setTotal(state, action) {
            state.total = action.payload;
        },
        setSortby(state, action) {
            state.sortBy = action.payload;
        },
        setUpdateDetailGalleryImages(state, action) {
            state.updateDetailGalleryImages = action.payload;
        },
        setSortOrder(state, action) {
            state.sortOrder = action.payload;
        },
        setSearch(state, action) {
            state.search = action.payload;
        },
        setUpdatesFormsTab(state, action) {
            state.updateFormsTab = action.payload;
        },
        setIsUpdateDetailsLoading(state, action) {
            state.isUpdateDetailsLoading = action.payload;
        }
    }
});

export const { actions: UpdatesReducerAction, name: UpdatesReducerName, reducer: UpdatesReducer } = updates;
