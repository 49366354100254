import Box from '@mui/material/Box';

import { useEffect } from 'react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReplyIcon from '@mui/icons-material/Reply';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { dropDownHelperInvestmentType, dropDownHelperParkingSpots, dropDownHelperStatus, dropDownHelperStorage } from './helper';
import { PropertiesSagaActions } from 'store/properties/saga';
import {
    ClientsAutocompleteListSelector,
    fiscalRepresentativesListSelector,
    LegalRepresentativesListSelector,
    selectedPropertySelector,
    SelectedPropertyUnitSelector,
    TechniciansListSelector
} from 'store/properties/selector';
import { Controller, useForm } from 'react-hook-form';
import { Country } from 'country-state-city';

import { countriesListType } from 'store/clients/types';
import { ClientsListDataType, PropertiesDataType, SupplierDataType } from 'store/properties/types';
import './style.scss';
import { PropertiesReducerAction } from 'store/properties/slice';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserSagaActions } from 'store/users/saga';
import { managersListSelector } from 'store/users/selector';
import { UserDetailsType } from 'store/users/types';
import UploadButton from 'ui-component/UploadButton/UploadButton';
import { emailRegex } from 'utils/helper';
import { AgenciesSagaAction } from 'store/agencies/saga';
import { agenciesListSelector, agentsListByAgencySelector } from 'store/agencies/selector';

export default function TextMobileStepper() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const schema = yup.object().shape({
        unit_name: yup.string().trim().required('Unit Name is required'),
        agent_email: yup.string().matches(emailRegex, 'Agent email is not valid').nullable(),
        agent_phone: yup.string().nullable(),
        client_email: yup.string().matches(emailRegex, 'Client email is not valid').nullable(),
        usable_area: yup.number().typeError('Usable area must be number').min(0, 'Usable Area must be positive').nullable(),
        private_gross_area: yup
            .number()
            .typeError('Private Gross Area must be number')
            .min(0, 'private Gross Area must be positive')
            .nullable(),
        total_gross_area: yup.number().typeError('Total Gross Area must be number').min(0, 'Total Gross Area must be positive').nullable(),
        total_area: yup.number().typeError('Total Area must be number').min(0, 'Total Area must be positive').nullable(),
        responsible_technician_name: yup
            .string()
            .matches(/^(?!\s+$)/, 'Responsible Technician Name is not valid')
            .nullable(),
        responsible_technician_email: yup.string().matches(emailRegex, 'Responsible Technician Email is not valid').nullable(),
        responsible_technician_phone: yup.string().nullable(),
        fiscal_representative_email: yup.string().matches(emailRegex, 'Fiscal Representative Email is not valid').nullable(),
        fiscal_representative_name: yup
            .string()
            .matches(/^(?!\s+$)/, 'Fiscal Representative Name is not valid')
            .nullable(),
        fiscal_representative_phone: yup.string().nullable(),
        legal_representative_email: yup.string().matches(emailRegex, 'Legal Representative Email is not valid').nullable(),
        legal_representative_name: yup
            .string()
            .matches(/^(?!\s+$)/, 'Legal Representative Name is not valid')
            .nullable(),
        legal_representative_phone: yup.string().nullable()
    });

    const handleBack = () => {
        navigate(-1);
        dispatch(PropertiesReducerAction.setSelectedPropertyUnit({}));
    };

    const selectedPropertyUnit = useSelector(SelectedPropertyUnitSelector);
    const fiscalRepresentatives = useSelector(fiscalRepresentativesListSelector);
    const legalRepresentatives = useSelector(LegalRepresentativesListSelector);
    const technicians = useSelector(TechniciansListSelector);

    useEffect(() => {
        const getAPIsCall = async () => {
            await dispatch(UserSagaActions.getUserByManagerRole());
            await dispatch(PropertiesSagaActions.fetchClientsAutoComplete({ search: '' }));
            await dispatch(PropertiesSagaActions.fetchSuppliersListByType({ supplier_type: 'fiscal' }));
            await dispatch(PropertiesSagaActions.fetchSuppliersListByType({ supplier_type: 'legal' }));
            await dispatch(PropertiesSagaActions.fetchSuppliersListByType({ supplier_type: 'project_manager' }));
            await dispatch(AgenciesSagaAction.fetchAllAgenciesList());
        };
        getAPIsCall();
        id && dispatch(PropertiesSagaActions.fetchPropertyUnitById(id));
    }, [dispatch, id]);

    const propertyDetails: PropertiesDataType = useSelector(selectedPropertySelector);
    const managersList: UserDetailsType[] = useSelector(managersListSelector);
    const clientsList: ClientsListDataType[] = useSelector(ClientsAutocompleteListSelector);
    const agenciesList = useSelector(agenciesListSelector);
    const agentsList = useSelector(agentsListByAgencySelector);
    const countriesList = Country.getAllCountries()?.map((country) => ({
        name: country?.name,
        code: country?.isoCode
    }));
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        control,
        setValue
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: selectedPropertyUnit && selectedPropertyUnit
    });

    useEffect(() => {
        watch('agency_id') && dispatch(AgenciesSagaAction.getAgentsByAgencyId(watch('agency_id')));
    }, [watch('agency_id')]);

    const onSubmit = async (values: { [key: string]: any }) => {
        const handleNavigate = (route: string) => navigate(route);
        const selectedAgent = agentsList?.find((agent: { [key: string]: string }) => agent?._id === values?.agent_id);
        const selectedAgency = agenciesList?.find((agency: { [key: string]: string }) => agency?._id === values?.agency_id);
        const data = {
            ...values,
            usable_area: parseInt(values?.usable_area),
            private_gross_area: parseInt(values?.private_gross_area),
            total_gross_area: parseInt(values?.total_gross_area),
            total_area: parseInt(values?.total_area),
            storage: values?.storage ?? dropDownHelperStorage[0]?.value,
            status: values.status,
            parking_spaces: values?.parking_spaces || dropDownHelperParkingSpots[0]?.value,
            properties_id: values?.properties_id || propertyDetails?._id,
            client_name: values?.client_name,
            agency: selectedAgency?.agency_name,
            agent_id: Boolean(values?.agent_id) ? values?.agent_id : null,
            agency_id: Boolean(values?.agency_id) ? values?.agency_id : null,
            agent_name: selectedAgent ? selectedAgent?.first_name + ' ' + selectedAgent?.last_name : ''
        };
        if (id) {
            await dispatch(PropertiesSagaActions.editPropertyUnit(data));
        } else {
            await dispatch(PropertiesSagaActions.addPropertyUnit({ data, handleNavigate }));
        }
    };

    const propertySectionsList = [
        { id: 1, label: propertyDetails?.property_section_1 },
        { id: 2, label: propertyDetails?.property_section_2 },
        { id: 3, label: propertyDetails?.property_section_3 },
        { id: 4, label: propertyDetails?.property_section_4 }
    ];

    const selectedClient = clientsList?.find(
        (client: { [key: string]: any }) => client?.name === (watch('client_name') ?? selectedPropertyUnit?.client_name)
    );
    const selectedAgent = agentsList?.find(
        (agent: { [key: string]: any }) => agent?._id === (watch('agent_id') ?? selectedPropertyUnit?.agent_id)
    );
    const selectedTechnician = technicians?.find(
        (technician: { [key: string]: any }) =>
            technician?.first_name + ' ' + technician?.last_name ===
            (watch('responsible_technician_name') ?? selectedPropertyUnit?.responsible_technician_name)
    );
    const selectedFiscalRepresentative = fiscalRepresentatives?.find(
        (fiscalRep: { [key: string]: any }) =>
            fiscalRep?.first_name + ' ' + fiscalRep?.last_name ===
            (watch('fiscal_representative_name') ?? selectedPropertyUnit?.fiscal_representative_name)
    );
    const selectedLegalRepresentative = legalRepresentatives?.find(
        (legalRep: { [key: string]: any }) =>
            legalRep?.first_name + ' ' + legalRep?.last_name ===
            (watch('legal_representative_name') ?? selectedPropertyUnit?.legal_representative_name)
    );

    useEffect(() => {
        if (selectedPropertyUnit) {
            reset({
                ...selectedPropertyUnit,
                agency_id: selectedPropertyUnit?.agency_id,
                agent_id: selectedPropertyUnit?.agent_id,
                building: selectedPropertyUnit?.building,
                manager: selectedPropertyUnit?.manager,
                storage: selectedPropertyUnit?.storage,
                purpose: selectedPropertyUnit?.purpose,
                agent_nationality: selectedPropertyUnit.agent_nationality,
                client_nationality: selectedClient?.country,
                client_phone: selectedClient?.phone,
                client_email: selectedClient?.email,
                client_id: selectedClient?._id ?? selectedPropertyUnit?.client_id
            });
        }
    }, [selectedPropertyUnit]);

    useEffect(() => {
        const getData = (data: any) => (!watch('status') ? data : '');
        setValue('client_phone', getData(selectedClient?.phone));
        setValue('client_email', getData(selectedClient?.email));
        setValue('client_name', getData(selectedClient?.name));
        setValue('client_nationality', getData(selectedClient?.country));
        setValue('purpose', getData(selectedClient?.client_type));
    }, [selectedClient, watch('status')]);

    useEffect(() => {
        setValue('agent_phone', selectedAgent?.phone);
        setValue('agent_email', selectedAgent?.email);
        setValue('agent_nationality', selectedAgent?.country);
    }, [selectedAgent]);

    useEffect(() => {
        setValue('responsible_technician_phone', selectedTechnician?.phone);
        setValue('responsible_technician_email', selectedTechnician?.email);
    }, [selectedTechnician]);
    useEffect(() => {
        setValue('fiscal_representative_phone', selectedFiscalRepresentative?.phone);
        setValue('fiscal_representative_email', selectedFiscalRepresentative?.email);
    }, [selectedFiscalRepresentative]);
    useEffect(() => {
        setValue('legal_representative_phone', selectedLegalRepresentative?.phone);
        setValue('legal_representative_email', selectedLegalRepresentative?.email);
    }, [selectedLegalRepresentative]);

    return (
        <form>
            <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                <Box sx={{ height: '100%', maxWidth: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid item marginBottom={'20px'} xs={12} className="media-uploads">
                            <Grid item xs={12} md={4} className="media-upload">
                                <UploadButton setValue={setValue} multiple={false} field={'image'} register={register} watch={watch} />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('unit_name') }}
                                helperText={errors?.unit_name?.message?.toString()}
                                error={!!errors?.unit_name}
                                required
                                fullWidth
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Unit Name"
                                {...register('unit_name')}
                            />
                        </Grid>
                        <Grid item className="input-property-forms" paddingRight={{ xs: '0px', md: '10px' }} xs={12} md={6} lg={2.4}>
                            <FormControl fullWidth>
                                <InputLabel id="building-select">Building</InputLabel>
                                <Controller
                                    control={control}
                                    name="building"
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Building"
                                                defaultValue={value}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    '.MuiInputBase-input': { fontWeight: '600' }
                                                }}
                                                {...register('building')}
                                            >
                                                {propertySectionsList
                                                    ?.filter((section: { [key: string]: any }) => Boolean(section?.label) && section)
                                                    ?.map((option) => (
                                                        <MenuItem value={option.label}>
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                                {/* {option.icon} */}
                                                                {option.label}
                                                            </div>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="purpose-select">Purpose</InputLabel>
                                <Controller
                                    control={control}
                                    name="purpose"
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Purpose"
                                                disabled={Boolean(watch('status'))}
                                                defaultValue={value}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    '.MuiInputBase-input': { fontWeight: '600' }
                                                }}
                                                {...register('purpose')}
                                            >
                                                {dropDownHelperInvestmentType.map((option) => (
                                                    <MenuItem value={option.value}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="manager-select">Manager</InputLabel>
                                <Controller
                                    control={control}
                                    name="manager"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                label="Manager"
                                                ref={ref}
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value}
                                            >
                                                {managersList?.map((option) => (
                                                    <MenuItem value={option._id}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.first_name + ' ' + option.last_name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="status-select">Status</InputLabel>
                                <Controller
                                    control={control}
                                    name="status"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                label="Status"
                                                ref={ref}
                                                onBlur={onBlur}
                                                readOnly={!id}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={dropDownHelperStatus[0]?.value}
                                            >
                                                {dropDownHelperStatus.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="agency-select">Agency</InputLabel>
                                <Controller
                                    control={control}
                                    name="agency_id"
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Agency"
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value}
                                                {...register('agency_id')}
                                            >
                                                {agenciesList?.map((option: any) => (
                                                    <MenuItem value={option?._id}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.agency_name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="agent-select">Agent</InputLabel>
                                <Controller
                                    control={control}
                                    name="agent_id"
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Agent"
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value}
                                                {...register('agent_id')}
                                            >
                                                {agentsList.map((option: { [key: string]: string }) => (
                                                    <MenuItem value={option?._id}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.first_name + ' ' + option.last_name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('agent_phone') }}
                                fullWidth
                                helperText={errors?.agent_phone?.message?.toString()}
                                error={!!errors?.agent_phone}
                                label="Agent Phone"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('agent_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('agent_email') }}
                                fullWidth
                                helperText={errors?.agent_email?.message?.toString()}
                                error={!!errors?.agent_email}
                                label="Agent email"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('agent_email')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="agent-nationality-select">Agent Nationality</InputLabel>
                                <Controller
                                    control={control}
                                    name="agent_nationality"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                ref={ref}
                                                label="Agent Nationality"
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: { maxHeight: 300 } // Limit the height of the dropdown
                                                    }
                                                }}
                                            >
                                                {countriesList?.map((option: countriesListType) => (
                                                    <MenuItem key={option.code} value={option?.name}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option?.name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="client-select">Client</InputLabel>
                                <Controller
                                    control={control}
                                    name="client_name"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                label="Client"
                                                ref={ref}
                                                disabled={Boolean(watch('status'))}
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: { maxHeight: 300 } // Limit the height of the dropdown
                                                    }
                                                }}
                                            >
                                                {clientsList?.map((option: ClientsListDataType) => (
                                                    <MenuItem value={option.name}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option?.name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('client_phone') }}
                                fullWidth
                                disabled={Boolean(watch('status'))}
                                helperText={errors?.client_phone?.message?.toString()}
                                error={!!errors?.client_phone}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Client Phone"
                                {...register('client_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('client_email') }}
                                fullWidth
                                disabled={Boolean(watch('status'))}
                                helperText={errors?.client_email?.message?.toString()}
                                error={!!errors?.client_email}
                                sx={{
                                    width: '100%',
                                    borderRadius: '12px',
                                    '.MuiInputBase-input': { fontWeight: '600' }
                                }}
                                label="Client Email"
                                {...register('client_email')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="client-nationality-select">Client Nationality</InputLabel>
                                <Controller
                                    control={control}
                                    name="client_nationality"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                value={value}
                                                ref={ref}
                                                disabled={Boolean(watch('status'))}
                                                label="Client Nationality"
                                                onBlur={onBlur}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                                defaultValue={value}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: { maxHeight: 300 } // Limit the height of the dropdown
                                                    }
                                                }}
                                            >
                                                {countriesList?.map((option: countriesListType) => (
                                                    <MenuItem key={option.code} value={option?.name}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option?.name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="building-select">Project Manager</InputLabel>
                                <Controller
                                    control={control}
                                    name="responsible_technician_name"
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Project manager"
                                                defaultValue={value}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    '.MuiInputBase-input': { fontWeight: '600' }
                                                }}
                                                {...register('responsible_technician_name')}
                                            >
                                                {technicians?.map((option: SupplierDataType) => (
                                                    <MenuItem key={option?._id} value={option.first_name + ' ' + option.last_name}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.first_name + ' ' + option.last_name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('responsible_technician_phone') }}
                                fullWidth
                                helperText={errors?.responsible_technician_phone?.message?.toString()}
                                error={!!errors?.responsible_technician_phone}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Project Manager Phone"
                                {...register('responsible_technician_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('responsible_technician_email') }}
                                fullWidth
                                helperText={errors?.responsible_technician_email?.message?.toString()}
                                error={!!errors?.responsible_technician_email}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Project Manager Email"
                                {...register('responsible_technician_email')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="building-select">Fiscal Representative</InputLabel>
                                <Controller
                                    control={control}
                                    name="fiscal_representative_name"
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Fiscal Representative"
                                                defaultValue={value}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    '.MuiInputBase-input': { fontWeight: '600' }
                                                }}
                                                {...register('fiscal_representative_name')}
                                            >
                                                {fiscalRepresentatives?.map((option: SupplierDataType) => (
                                                    <MenuItem key={option?._id} value={option.first_name + ' ' + option.last_name}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.first_name + ' ' + option.last_name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('fiscal_representative_phone') }}
                                fullWidth
                                helperText={errors?.fiscal_representative_phone?.message?.toString()}
                                error={!!errors?.fiscal_representative_phone}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Fiscal Representative Phone"
                                {...register('fiscal_representative_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('fiscal_representative_email') }}
                                fullWidth
                                helperText={errors?.fiscal_representative_email?.message?.toString()}
                                error={!!errors?.fiscal_representative_email}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Fiscal Representative Email"
                                {...register('fiscal_representative_email')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="building-select">Legal Representative</InputLabel>
                                <Controller
                                    control={control}
                                    name="legal_representative_name"
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Legal Representative"
                                                defaultValue={value}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    '.MuiInputBase-input': { fontWeight: '600' }
                                                }}
                                                {...register('legal_representative_name')}
                                            >
                                                {legalRepresentatives?.map((option: SupplierDataType) => (
                                                    <MenuItem key={option?._id} value={option.first_name + ' ' + option.last_name}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.first_name + ' ' + option.last_name}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                            {/* <TextField
                                InputLabelProps={{ shrink: !!watch('legal_representative_name') }}
                                fullWidth
                                helperText={errors?.legal_representative_name?.message?.toString()}
                                error={!!errors?.legal_representative_name}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Legal Representative"
                                {...register('legal_representative_name')}
                            /> */}
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('legal_representative_phone') }}
                                fullWidth
                                helperText={errors?.legal_representative_phone?.message?.toString()}
                                error={!!errors?.legal_representative_phone}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Legal Representative Phone"
                                {...register('legal_representative_phone')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('legal_representative_email') }}
                                fullWidth
                                helperText={errors?.legal_representative_email?.message?.toString()}
                                error={!!errors?.legal_representative_email}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Legal Representative Email"
                                {...register('legal_representative_email')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{
                                    shrink: !!watch('usable_area') || watch('usable_area') === 0 || Boolean(errors?.usable_area)
                                }}
                                fullWidth
                                helperText={errors?.usable_area?.message?.toString()}
                                error={!!errors?.usable_area}
                                defaultValue={0}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                type="number"
                                label="Usable Area"
                                {...register('usable_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{
                                    shrink:
                                        !!watch('private_gross_area') ||
                                        watch('private_gross_area') === 0 ||
                                        Boolean(errors?.private_gross_area)
                                }}
                                fullWidth
                                helperText={errors?.private_gross_area?.message?.toString()}
                                error={!!errors?.private_gross_area}
                                defaultValue={0}
                                type="number"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Private Gross Area"
                                {...register('private_gross_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{
                                    shrink:
                                        !!watch('total_gross_area') || watch('total_gross_area') === 0 || Boolean(errors?.total_gross_area)
                                }}
                                fullWidth
                                helperText={errors?.total_gross_area?.message?.toString()}
                                error={!!errors?.total_gross_area}
                                defaultValue={0}
                                type="number"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Exterior Area"
                                {...register('total_gross_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{
                                    shrink: !!watch('total_area') || watch('total_area') === 0 || Boolean(errors?.total_area)
                                }}
                                fullWidth
                                helperText={errors?.total_area?.message?.toString()}
                                error={!!errors?.total_area}
                                defaultValue={0}
                                type="number"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Total Gross Area"
                                {...register('total_area')}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={2.4}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch('m2_value') }}
                                fullWidth
                                label="M2 Value"
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                {...register('m2_value')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="input-property-forms" width={'100%'} xl={12} lg={12}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="parking-spaces-select">Parking Spaces</InputLabel>
                                <Controller
                                    control={control}
                                    name="parking_spaces"
                                    render={({ field: { onChange, onBlur, value, ref } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                onChange={onChange}
                                                ref={ref}
                                                label="Parking Spaces"
                                                onBlur={onBlur}
                                                value={value}
                                                defaultValue={dropDownHelperParkingSpots[0]?.value}
                                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                            >
                                                {dropDownHelperParkingSpots.map((option) => (
                                                    <MenuItem value={option.value}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={6}
                            lg={6}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="storage-select">Storage</InputLabel>
                                <Controller
                                    control={control}
                                    name="storage"
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            key={value}
                                            labelId={value}
                                            onChange={onChange}
                                            label="storage"
                                            ref={ref}
                                            onBlur={onBlur}
                                            value={value}
                                            defaultValue={dropDownHelperStorage[0]?.value}
                                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                        >
                                            {dropDownHelperStorage.map((option) => (
                                                <MenuItem value={option.value}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid marginY={'10px'} display={'flex'} gap={'20px'}>
                        <Button variant="outlined" startIcon={<ReplyIcon className="back-icon" />} onClick={handleBack}>
                            Back to Property
                        </Button>
                        <Button
                            variant="outlined"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            startIcon={<ForwardOutlinedIcon className="forward-icon" />}
                        >
                            Save
                        </Button>
                    </Grid>
                </Box>
            </Box>
        </form>
    );
}
