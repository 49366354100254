import { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'hooks/useDebounce';
import { useLocation, useNavigate } from 'react-router-dom';
import routeConstant from 'routes/routeConstant';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, useMediaQuery } from '@mui/material';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons-react';

import './style.scss';
import { selectedModulesSelector, sidepanelStateSelector } from 'store/sidepanel/selector';
import { SidepanelReducer, SidepanelReducerAction, SidepanelReducerName } from 'store/sidepanel/slice';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { SidePanelSaga, SidePanelSagaAction, SidePanelSagaName } from 'store/sidepanel/saga';
import useAuth from 'hooks/useAuth';
import { sideBarItem } from '../MenuList';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen, search } = useSelector(sidepanelStateSelector);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useInjectReducer({
        reducer: SidepanelReducer,
        key: SidepanelReducerName
    });
    useInjectSaga({
        saga: SidePanelSaga,
        key: SidePanelSagaName
    });

    const { loggedInUserType, user } = useAuth();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();
    const debounceQuery = useDebounce({ value: search });
    const selectedModules = useSelector(selectedModulesSelector);
    const agency_id = loggedInUserType?.toLocaleLowerCase().includes('agency') ? user?._id : '';
    const agent_id = loggedInUserType?.toLocaleLowerCase().includes('agent') ? user?._id : '';
    const client_id = loggedInUserType?.toLocaleLowerCase().includes('client') ? user?._id : '';
    const sidebarItems = sideBarItem(String(loggedInUserType));

    useLayoutEffect(() => {
        const sidebarList = sidebarItems && sidebarItems[0]?.children?.map((item) => item?.id);
        dispatch(
            SidepanelReducerAction.setSelectedModules(
                sidebarList?.filter((item) => !(item?.includes('dashboard') || item?.includes('financials')))
            )
        );
    }, []);

    useEffect(() => {
        if (Boolean(debounceQuery)) {
            navigate(routeConstant.globalSearch);
            dispatch(
                SidePanelSagaAction.fetchGlobalSearchData({
                    search,
                    agency_id,
                    agent_id,
                    client_id,
                    modules: encodeURIComponent(selectedModules)
                })
            );
        } else {
            dispatch(SidepanelReducerAction.setGlobalSearch(''));
        }
        if (!Boolean(debounceQuery)) pathname.includes('search') && navigate(-1);
    }, [debounceQuery]);

    useEffect(() => {
        pathname.includes('search') &&
            Boolean(debounceQuery) &&
            dispatch(
                SidePanelSagaAction.fetchGlobalSearchData({
                    search,
                    agency_id,
                    agent_id,
                    client_id,
                    modules: encodeURIComponent(selectedModules)
                })
            );
    }, [selectedModules]);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: '19%',
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box
                    onClick={() => dispatch(SidepanelReducerAction.activeItem([]))}
                    component="span"
                    sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
                >
                    <LogoSection />
                </Box>
                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={() => {
                            dispatch(SidepanelReducerAction.openDrawer(!drawerOpen));
                        }}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                )}
            </Box>
            <Grid className="header-search-container" sx={{ display: 'flex', padding: '0 0 0 20px', width: '100%' }}>
                <Grid
                    className="header-search-bar"
                    sx={{ display: 'flex', padding: '0px', width: '100%', justifyContent: 'space-between' }}
                >
                    <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                        <SearchSection />
                    </Grid>
                    <Grid sx={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                        <NotificationSection />
                        <ProfileSection />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Header;
